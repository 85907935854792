import React, { useEffect, useRef, useState } from "react";
import { Message } from "./Message";
import io from "socket.io-client";
import "./index.css";
import styles from "./App.module.css";

const socket = io();

interface MessageData {
  author: string;
  message: string;
}

function useScrollToBottomOnUpdate() {
  useEffect(() => {
    // Scroll to the bottom when the component updates
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  });
}

function App() {
  const [messages, setMessages] = useState<{
    prevMessages: MessageData[];
    currentQuestion: string | null;
    currentResponse: string | null;
  }>({
    prevMessages: [],
    currentQuestion: null,
    currentResponse: null,
  });
  const [text, setText] = useState<string>("");
  const [sessionId, setSessionId] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  useScrollToBottomOnUpdate();

  useEffect(() => {
    if (sessionId.length === 0) {
      const localSessionId = localStorage.getItem("sessionId");
      if (localSessionId) {
        setSessionId(localSessionId);
        socket.emit("get_history", { sessionId: localSessionId });
        socket.on(
          "history",
          (response: { data: { question: string; response: string }[] }) => {
            const restoredMessages = response.data.flatMap((entry) => [
              { author: "You", message: entry.question },
              { author: "Assistant", message: entry.response },
            ]);
            setMessages((prev) => ({
              ...prev,
              prevMessages: restoredMessages,
            }));
          }
        );
      } else {
        socket.emit("create_session");
        socket.on("session_created", (response: { sessionId: string }) => {
          setSessionId(response.sessionId);
          localStorage.setItem("sessionId", response.sessionId);
        });
      }
    }
  }, [sessionId]);

  useEffect(() => {
    const handler = (token: string) => {
      setMessages((prev) => ({
        ...prev,
        currentResponse: prev.currentResponse + token,
      }));
    };
    socket.on("message_part", handler);
    return () => {
      socket.off("message_part", handler);
    };
  }, []);

  useEffect(() => {
    const handler = (token: string) => {
      setMessages((prev) => ({
        prevMessages: [
          ...prev.prevMessages,
          { author: "You", message: prev.currentQuestion || "" },
          { author: "Assistant", message: prev.currentResponse || "" },
        ],
        currentQuestion: null,
        currentResponse: null,
      }));
    };
    socket.on("message_complete", handler);
    return () => {
      socket.off("message_complete", handler);
    };
  }, []);

  useEffect(() => {
    if (!messages.currentQuestion && messages.prevMessages.length > 0) {
      inputRef.current?.focus();
    }
  }, [messages]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && text.trim() !== "") {
      setText("");
      setMessages((prev) => ({
        ...prev,
        currentQuestion: text,
        currentResponse: "",
      }));
      socket.emit("query", { query: text, sessionId: sessionId });
    }
  };

  const restart = () => {
    localStorage.removeItem("sessionId");
    setSessionId("");
    setMessages({
      prevMessages: [],
      currentQuestion: null,
      currentResponse: null,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.messages}>
          <Message author="Assistant" message="How can I help you?" />
          {messages.prevMessages.map((message, index) => (
            <Message
              key={index}
              author={message.author}
              message={message.message}
            />
          ))}
          {messages.currentQuestion !== null && (
            <Message author="You" message={messages.currentQuestion} />
          )}
          {messages.currentResponse !== null && (
            <Message
              loading
              author="Assistant"
              message={messages.currentResponse}
            />
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <input
          type="text"
          ref={inputRef}
          className={styles.prompt}
          value={text}
          onChange={(event) => setText(event.target.value)}
          placeholder="Type your question and press Enter"
          disabled={messages.currentQuestion !== null}
          onKeyDown={handleKeyPress}
        />
        {messages.prevMessages.length !== 0 && (
          <button type="button" className={styles.restart} onClick={restart}>
            Restart
          </button>
        )}
      </div>
    </div>
  );
}

export default App;

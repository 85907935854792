import c from "classnames";
import { ReactComponent as Loader } from "./loader.svg";
import styles from "./Message.module.css";

export function Message(props: {
  author: string;
  message: string;
  loading?: boolean;
}) {
  return (
    <div
      className={c(styles.message, {
        [styles.incomming]: props.author === "Assistant",
        [styles.outgoing]: props.author === "You",
      })}
    >
      <h5 className={styles.author}>{props.author}</h5>
      {props.message && (
        <div className={styles.bubble}>
          <p>{props.message}</p>
        </div>
      )}
      {props.loading && <Loader className={styles.loader} />}
    </div>
  );
}
